export enum ManagerBookingStateValues {
    STATE_OPTION = 'option',
    STATE_CONFIRMED = 'confirmed',
    STATE_OWNER_TO_BE_PAID = 'owner_to_pay',
    STATE_ARCHIVED = 'archived',
    STATE_CANCEL_REPORT = 'cancel_report',
    STATE_CANCEL = 'canceled',
    STATE_DELETED = 'deleted',
}

export enum ManagerBookingStateTransition {
    TO_STATE_OPTION = 'to_option',
    TO_STATE_CONFIRMED = 'to_confirmed',
    TO_STATE_OWNER_TO_BE_PAID = 'to_owner_be_paid',
    TO_STATE_ARCHIVED = 'to_archived',
    TO_STATE_CANCEL_REPORT = 'to_cancel_report',
    TO_STATE_CANCEL = 'to_cancel',
    TO_STATE_DELETED = 'to_deleted',
}

export enum ManagerBookingAcceptedStateTransition {
    TO_ACCEPTED_STATE_PENDING = 'to_pending',
    TO_ACCEPTED_STATE_ACCEPTED = 'to_accepted',
    TO_ACCEPTED_STATE_NEGOTIATED = 'to_negotiated',
    TO_ACCEPTED_STATE_REJECTED = 'to_rejected',
}

export enum ManagerBookingAcceptedStateValues {
    ACCEPTED_STATE_PENDING = 'pending',
    ACCEPTED_STATE_ACCEPTED = 'accepted',
    ACCEPTED_STATE_NEGOTIATED = 'negotiated',
    ACCEPTED_STATE_REJECTED = 'rejected',
}
