import lazyWithRetry from 'services/lazyWithRetry';
import { Features, Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const TeamListPage = lazyWithRetry(() => import('./List'));
const TeamAddPage = lazyWithRetry(() => import('./Add'));
const TeamShowPage = lazyWithRetry(() => import('./Show'));
const TeamEditPage = lazyWithRetry(() => import('./Edit'));
const MyTeamPage = lazyWithRetry(() => import('./My'));

export const paths = {
    LIST: '/team',
    ADD: '/team/add',
    MY: '/team/my-team',
    SHOW: '/team/:id/show',
    EDIT: '/team/:id/edit',
};

export const routes: RouteCollection = {
    list: {
        exact: true,
        path: paths.LIST,
        component: TeamListPage,
        roles: [Roles.SUPER_ADMIN],
    },
    add: {
        exact: true,
        path: paths.ADD,
        component: TeamAddPage,
        roles: [Roles.SUPER_ADMIN],
    },
    show: {
        exact: true,
        path: paths.SHOW,
        component: TeamShowPage,
        roles: [Roles.SUPER_ADMIN],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: TeamEditPage,
        roles: [Roles.SUPER_ADMIN],
    },
    my: {
        exact: true,
        path: paths.MY,
        component: MyTeamPage,
        roles: [Roles.BASE_USER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
};
