import { paths, routes } from 'pages/user/routes';
import api from 'resources/user/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/user/selectors';
import { actions } from 'store/user/slice';
import { UserApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<UserApiItem, typeof paths, typeof api, typeof actions, typeof selectors, typeof routes> = {
    name: 'user',
    translationKey: 'user',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/user')),
};

export default resource;
