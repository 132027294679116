export enum Locales {
    FR = 'fr_FR',
    EN = 'en_US',
}

export enum Roles {
    TEAM_MANAGER = 'ROLE_TEAM_MANAGER',
    BASE_USER = 'ROLE_TEAM_USER',
    OWNER = 'ROLE_OWNER',
    ACCOUNTING = 'ROLE_ACCOUNTING',
    BUTLER = 'ROLE_BUTLER',
    TRADER = 'ROLE_TRADER',
    WEBMASTER = 'ROLE_WEBMASTER',
    ADMIN = 'ROLE_ADMIN',
    SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
    ALLOWED_TO_SWITCH = 'ROLE_ALLOWED_TO_SWITCH',
    MANAGER = 'ROLE_MANAGER',
    USER = 'ROLE_USER',
    PROVIDER = 'ROLE_PROVIDER',
}

export enum Features {
    RENTAL_AGENCY = 'RENTAL_AGENCY',
    RENTAL_WEBSITE = 'RENTAL_WEBSITE',
    RENTAL_MANAGER = 'RENTAL_MANAGER',
    TEAM_OWNER = 'TEAM_OWNER',
    TEAM_PROVIDER = 'TEAM_PROVIDER',
}

export enum Orders {
    ASC = 'asc',
    DESC = 'desc',
}

export enum PreferencesOfEmail {
    PREFERENCE_EMAIL_1 = 'pe_1',
    PREFERENCE_EMAIL_2 = 'pe_2',
    PREFERENCE_EMAIL_COPY2 = 'pe_copy2',
}

export enum PreferencesOfContact {
    PREFERENCE_CONTACT_PHONE1 = 'pc_phone1',
    PREFERENCE_CONTACT_PHONE2 = 'pc_phone2',
    PREFERENCE_CONTACT_EMAIL = 'pc_email',
}

export enum BathroomType {
    BATHROOM_TYPE_JOINED = 'joined',
    BATHROOM_TYPE_SEPARATED = 'separated',
    BATHROOM_TYPE_SHARED = 'shared',
}

export enum EnquiryStateValues {
    STATE_CREATED = 'CREATED',
    STATE_ASSIGNED = 'ASSIGNED',
    STATE_WAITING_FOR_CUSTOMER_INFORMATION = 'WAITING_FOR_CUSTOMER_INFORMATION',
    STATE_SELECTION_SENT_TO_CUSTOMER = 'SELECTION_SENT_TO_CUSTOMER',
    STATE_OPTIONS_SENT = 'OPTIONS_SENT',
    STATE_CANCELED = 'CANCELED',
    STATE_VALIDATED = 'VALIDATED',
}

export enum EnquiryTransition {
    TO_CREATED = 'to_created',
    TO_ASSIGNED = 'to_assigned',
    TO_CANCELED = 'to_canceled',
    TO_WAITING_FOR_CUSTOMER_INFORMATION = 'to_waiting_for_customer_information',
    TO_SELECTION_SENT_TO_CUSTOMER = 'to_selection_sent_to_customer',
    TO_OPTIONS_SENT = 'to_options_sent',
    TO_BOOKING_TO_CREATE = 'to_booking_to_create',
    TO_VALIDATED = 'to_validated',
}

export enum CacheKeys {
    ADVANCED_SEARCH = 'advancedSearchRentals',
    ADVANCED_SEARCH_PRICES = 'priceResults',
    ADVANCED_SEARCH_UNAVAILABILITIES = 'unavailabilitiesResults',
}

export enum PaymentMode {
    PAYMENT_MODE_BANK_TRANSFER = 'bank_transfer',
    PAYMENT_MODE_BANK_CHECK = 'bank_check',
}

export enum BillTypeChoice {
    CUSTOMER = 'customer',
    PARTNER = 'partner',
}

export enum BookingStateValues {
    STATE_CANCEL_REFUND = 'cancel_refund',
    STATE_CANCEL_REPORT = 'cancel_report',
    STATE_CANCEL = 'canceled',
    STATE_OPTION = 'option',
    STATE_CONFIRMED = 'confirmed',
    STATE_PAID = 'paid',
    STATE_OWNER_TO_BE_PAID = 'owner_to_pay',
    STATE_PARTNER_TO_BE_PAID = 'partner_to_pay',
    STATE_ARCHIVED = 'archived',
    STATE_DELETED = 'deleted',
}

export enum YesNo {
    YES = 'true',
    NO = 'false',
}

export enum WidgetCode {
    PLANNING_CHECKIN_CHECKOUT_OWNER_CODE = 'planning_cio_owner',
    PLANNING_CHECKIN_CHECKOUT_BOOKING_CODE = 'planning_cio_booking',
    PLANNING_RENTAL_BOOKING_CODE = 'planning_rental_booking',
    PLANNING_CHECKIN_CHECKOUT_MANAGER_CODE = 'planning_cio_manager',
    PLANNING_CHECKIN_CHECKOUT_PROVIDER_CODE = 'planning_cio_provider',
    PLANNING_RENTAL_MANAGER_CODE = 'planning_rental_manager',
    WAITING_BOOKING_OPTIONS_CODE = 'waiting_booking_options',
    WAITING_BOOKING_CONFIRMED_CODE = 'waiting_booking_confirmed',
    WAITING_PRE_OPTIONS_CODE = 'waiting_pre_options',
}

export enum WidgetSize {
    XS = '3',
    S = '4',
    M = '6',
    L = '8',
    XL = '12',
}
