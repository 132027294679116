import { paths, routes } from 'pages/customer/routes';
import api from 'resources/customer/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/customer/selectors';
import { actions } from 'store/customer/slice';
import { CustomerApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<CustomerApiItem, typeof paths, typeof api, typeof actions, typeof selectors, typeof routes> = {
    name: 'customer',
    translationKey: 'customer',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/customer')),
};

export default resource;
