import { RentalApiItem } from 'features/Rental/types';
import lazyWithRetry from 'services/lazyWithRetry';
import { Resource } from 'typings/shared';
import api from './api';
import { paths, routes } from './pages/routes';
import { actions, selectors } from './store/';

export const translationKey = 'Rental';

const resource: Resource<RentalApiItem, typeof paths, typeof api, typeof actions, typeof selectors, typeof routes> = {
    name: 'rental',
    translationKey,
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('./pages')),
};

export default resource;
