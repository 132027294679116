import { paths, routes } from 'pages/team/routes';
import api from 'resources/team/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/team/selectors';
import { actions } from 'store/team/slice';
import { TeamApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<TeamApiItem, typeof paths, typeof api, typeof actions, typeof selectors, typeof routes> = {
    name: 'team',
    translationKey: 'team',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/team')),
};

export default resource;
