import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ManagerBookingListType, ManagerBookingState } from 'features/ManagerBooking/types';
import { ManagerBookingStateValues } from 'features/ManagerBooking/types/enums';
import { MUISortOptions } from 'mui-datatables';
import { resourceInitialState } from 'store/ResourceSlice';
import { OrNull } from 'typings/shared';

const { list: listState } = resourceInitialState();

export const stateByType = {
    option: [ManagerBookingStateValues.STATE_OPTION],
    confirmed: [ManagerBookingStateValues.STATE_CONFIRMED, ManagerBookingStateValues.STATE_OWNER_TO_BE_PAID],
    archived: [
        ManagerBookingStateValues.STATE_ARCHIVED,
        ManagerBookingStateValues.STATE_CANCEL_REPORT,
        ManagerBookingStateValues.STATE_DELETED,
        ManagerBookingStateValues.STATE_CANCEL,
    ],
    all: [
        ManagerBookingStateValues.STATE_OPTION,
        ManagerBookingStateValues.STATE_CONFIRMED,
        ManagerBookingStateValues.STATE_OWNER_TO_BE_PAID,
        ManagerBookingStateValues.STATE_ARCHIVED,
        ManagerBookingStateValues.STATE_CANCEL_REPORT,
        ManagerBookingStateValues.STATE_DELETED,
        ManagerBookingStateValues.STATE_CANCEL,
    ],
};

export const initialState: ManagerBookingState = {
    listOption: { ...listState, filters: [[], [], [], [], [], [], [], [], [], [], stateByType.option] },
    listConfirmed: { ...listState, filters: [[], [], [], [], [], [], [], [], [], [], stateByType.confirmed] },
    listArchived: {
        ...listState,
        filters: [[], [], [], [], [], [], [], [], [], [], stateByType.archived],
    },
    listAll: {
        ...listState,
        filters: [[], [], [], [], [], [], [], [], [], [], stateByType.all],
    },
    currentList: 'Option',
};

const slice = createSlice({
    name: 'manager_booking',
    initialState,
    reducers: {
        setCurrentPage: (state: ManagerBookingState, action: PayloadAction<number>): void => {
            state[`list${state.currentList}` as const].currentPage = action.payload;
        },
        setItemsPerPage: (state: ManagerBookingState, action: PayloadAction<number>): void => {
            if (state[`list${state.currentList}` as const].itemsPerPage !== action.payload) {
                state[`list${state.currentList}` as const].currentPage = 1;
            }
            state[`list${state.currentList}` as const].itemsPerPage = action.payload;
        },
        setSearch: (state: ManagerBookingState, action: PayloadAction<OrNull<string>>): void => {
            if (state[`list${state.currentList}` as const].search !== action.payload) {
                state[`list${state.currentList}` as const].currentPage = 1;
            }
            state[`list${state.currentList}` as const].search = action.payload;
        },
        setFilters: (state: ManagerBookingState, action: PayloadAction<Array<any>>): void => {
            if (state[`list${state.currentList}` as const].filters !== action.payload) {
                state[`list${state.currentList}` as const].currentPage = 1;
            }
            state[`list${state.currentList}` as const].filters = action.payload;
        },
        setSort: (state: ManagerBookingState, action: PayloadAction<MUISortOptions>): void => {
            state[`list${state.currentList}` as const].sort = action.payload;
        },
        setColumnVisibility: (
            state: ManagerBookingState,
            action: PayloadAction<{ column: string; visible: boolean }>,
        ): void => {
            state[`list${state.currentList}` as const].columns[action.payload.column] = action.payload.visible;
        },
        setCurrentList: (state: ManagerBookingState, action: PayloadAction<ManagerBookingListType>): void => {
            state.currentList = action.payload;
        },
        setColumnOrder: (state: ManagerBookingState, action: PayloadAction<Array<number>>): void => {
            state[`list${state.currentList}` as const].columnOrder = action.payload;
        },
    },
});

export const managerBooking = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
