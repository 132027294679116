/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { stateByType } from 'store/booking/slice';
import { stateByType as enquiryStateByType } from 'store/enquiry/slice';
import { stateByType as managerBookingStateByType } from 'features/ManagerBooking/store';

const migrations = {
    2: (state: any): any => ({
        ...state,
        booking: {
            ...state.booking,
            listOption: {
                ...state.booking.listOption,
                filters: [[], [], [], [], [], [], [], [], [], [], [], stateByType.option],
            },
            listConfirmed: {
                ...state.booking.listConfirmed,
                filters: [[], [], [], [], [], [], [], [], [], [], [], stateByType.confirmed],
            },
            listDue: {
                ...state.booking.listDue,
                filters: [[], [], [], [], [], [], [], [], [], [], [], stateByType.due],
            },
            listArchived: {
                ...state.booking.listArchived,
                filters: [[], [], [], [], [], [], [], [], [], [], [], stateByType.archived],
            },
        },
    }),
    3: (state: any): any => ({
        ...state,
        booking: {
            ...state.booking,
            listOption: {
                ...state.booking.listOption,
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.option],
            },
            listConfirmed: {
                ...state.booking.listConfirmed,
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.confirmed],
            },
            listDue: {
                ...state.booking.listDue,
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.due],
            },
            listArchived: {
                ...state.booking.listArchived,
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.archived],
            },
        },
    }),
    4: (state: any): any => {
        const { list: listAccounting, ...accountingState } = state.accounting;
        return {
            ...state,
            accounting: {
                ...accountingState,
                listBank: {
                    ...listAccounting,
                    columns: {
                        rentalTitle: false,
                        bookingNumber: true,
                        bookingStartAt: false,
                        bookingEndAt: true,
                        bookingNetAmount: true,
                        bookingPaidAmount: true,
                        regularisationAmount: true,
                        ownerPaidAmount: true,
                        paymentAmount: true,
                        paymentDate: true,
                        paymentMode: true,
                    },
                },
                listCommission: {
                    ...listAccounting,
                    columns: {
                        rentalTitle: false,
                        bookingNumber: true,
                        bookingStartAt: false,
                        bookingEndAt: true,
                        commissionAmount: true,
                        commissionRate: true,
                        partnerCommissionAmount: true,
                        partnerCommissionRate: false,
                        agencyCommissionAmount: true,
                    },
                },
                listSales: {
                    ...listAccounting,
                    columns: {
                        rentalTitle: false,
                        bookingNumber: true,
                        bookingStartAt: false,
                        bookingEndAt: true,
                        bookingNetAmount: true,
                        bookingPaidAmount: true,
                        serviceFeeAmount: true,
                        serviceFeeRate: false,
                        touristTaxAmount: true,
                        commissionAmount: true,
                        commissionRate: false,
                        partnerCommissionAmount: true,
                        partnerCommissionRate: false,
                        agencyCommissionAmount: true,
                    },
                },
            },
            bed: {
                ...state.bed,
                list: {
                    ...state.bed.list,
                    columns: {
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                    },
                },
            },
            booking: {
                ...state.booking,
                listOption: {
                    ...state.booking.listOption,
                    columns: {
                        number: false,
                        customerFullName: false,
                        customerBilled: false,
                        checkIn: true,
                        checkOut: true,
                        rental: true,
                        customer: true,
                        partner: true,
                        createdAt: true,
                        paidAmount: false,
                        totalAmount: true,
                        state: true,
                    },
                },
                listConfirmed: {
                    ...state.booking.listConfirmed,
                    columns: {
                        number: false,
                        customerFullName: false,
                        customerBilled: false,
                        checkIn: true,
                        checkOut: true,
                        rental: true,
                        customer: true,
                        partner: true,
                        createdAt: true,
                        paidAmount: true,
                        totalAmount: true,
                        state: true,
                    },
                },
                listDue: {
                    ...state.booking.listDue,
                    columns: {
                        number: false,
                        customerFullName: false,
                        customerBilled: false,
                        checkIn: false,
                        checkOut: true,
                        rental: true,
                        customer: true,
                        partner: true,
                        createdAt: false,
                        paidAmount: true,
                        totalAmount: true,
                        state: true,
                    },
                },
                listArchived: {
                    ...state.booking.listArchived,
                    columns: {
                        number: false,
                        customerFullName: false,
                        customerBilled: false,
                        checkIn: false,
                        checkOut: true,
                        rental: true,
                        customer: true,
                        partner: true,
                        createdAt: false,
                        paidAmount: false,
                        totalAmount: true,
                        state: true,
                    },
                },
            },
            cancelReason: {
                ...state.cancelReason,
                list: {
                    ...state.cancelReason.list,
                    columns: {
                        title: true,
                    },
                },
            },
            competitor: {
                ...state.competitor,
                list: {
                    ...state.competitor.list,
                    columns: {
                        title: true,
                    },
                },
            },
            customer: {
                ...state.customer,
                list: {
                    ...state.customer.list,
                    columns: {
                        lastName: true,
                        company: false,
                        email: true,
                        phone: true,
                        assignedToPartner: true,
                        assignedTo: true,
                    },
                },
            },
            enquiry: {
                ...state.enquiry,
                list: {
                    ...state.enquiry.list,
                    columns: {
                        receivedAt: true,
                        name: true,
                        assignedTo: true,
                        period: true,
                        origin: true,
                        currentPlace: true,
                    },
                },
            },
            furnishing: {
                ...state.furnishing,
                list: {
                    ...state.furnishing.list,
                    columns: {
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                        roomTypes: true,
                    },
                },
            },
            highlight: {
                ...state.highlight,
                list: {
                    ...state.highlight.list,
                    columns: {
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                    },
                },
            },
            location: {
                ...state.location,
                list: {
                    ...state.location.list,
                    columns: {
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                    },
                },
            },
            manager: {
                ...state.manager,
                list: {
                    ...state.manager.list,
                    columns: {
                        lastName: true,
                        company: false,
                        email: true,
                        phone: true,
                    },
                },
            },
            origin: {
                ...state.origin,
                list: {
                    ...state.origin.list,
                    columns: {
                        title: true,
                    },
                },
            },
            owner: {
                ...state.owner,
                list: {
                    ...state.owner.list,
                    columns: {
                        lastName: true,
                        company: true,
                        email: true,
                        phone: true,
                    },
                },
            },
            partner: {
                ...state.partner,
                list: {
                    ...state.partner.list,
                    columns: {
                        lastName: true,
                        company: true,
                        email: true,
                        phone: true,
                    },
                },
            },
            paymentMode: {
                ...state.paymentMode,
                list: {
                    ...state.paymentMode.list,
                    columns: {
                        code: true,
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                        chargesRate: true,
                    },
                },
            },
            poi: {
                ...state.poi,
                list: {
                    ...state.poi.list,
                    columns: {
                        code: true,
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                        unit: true,
                    },
                },
            },
            rental: {
                ...state.rental,
                list: {
                    ...state.rental.list,
                    columns: {
                        title: true,
                        capacity: true,
                        bedroomCount: true,
                        bathroomCount: false,
                        published: false,
                        planningModifiedAt: true,
                        planningModifiedBy: true,
                    },
                },
            },
            requestContact: {
                ...state.requestContact,
                list: {
                    ...state.requestContact.list,
                    columns: {
                        createdAt: true,
                        subject: true,
                        contact: true,
                        email: true,
                        phoneNumber: true,
                    },
                },
            },
            roomType: {
                ...state.roomType,
                list: {
                    ...state.roomType.list,
                    columns: {
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                        position: true,
                    },
                },
            },
            season: {
                ...state.season,
                list: {
                    ...state.season.list,
                    columns: {
                        periodStart: true,
                        periodEnd: true,
                        period: true,
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                        minBookingDays: true,
                        nbDaysLimitPaidBalance: true,
                    },
                },
            },
            team: {
                ...state.team,
                list: {
                    ...state.team.list,
                    columns: {
                        name: true,
                        rentalAgency: true,
                    },
                },
            },
            user: {
                ...state.user,
                list: {
                    ...state.user.list,
                    columns: {
                        lastName: true,
                        email: true,
                        roles: true,
                    },
                },
            },
        };
    },
    5: (state: any): any => ({
        ...state,
        team: {
            ...state.team,
            myTeam: null,
        },
    }),
    6: (state: any): any => ({
        ...state,
        rental: {
            ...state.rental,
            list: {
                ...state.rental.list,
                columns: {
                    location: true,
                },
            },
        },
    }),
    7: (state: any): any => ({
        ...state,
        rental: {
            ...state.rental,
            add: {
                ...state.rental.add,
                step0: {
                    declaLoc: '',
                },
            },
        },
    }),
    8: (state: any): any => ({
        ...state,
        booking: {
            ...state.booking,
            listOption: {
                ...state.booking.listOption,
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.option, []],
            },
            listConfirmed: {
                ...state.booking.listConfirmed,
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.confirmed, []],
            },
            listDue: {
                ...state.booking.listDue,
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.due, []],
            },
            listArchived: {
                ...state.booking.listArchived,
                filters: [[], [], [], [], [], [], [], [], [], [], [], stateByType.archived, []],
            },
        },
    }),
    9: (state: any): any => ({
        ...state,
        rental: {
            ...state.rental,
            add: {
                ...state.rental.add,
                step1: {
                    ...state.rental.add.step1,
                    managed: undefined,
                },
            },
        },
    }),
    10: (state: any): any => ({
        ...state,
        rental: {
            ...state.rental,
            add: {
                declalocStep: state.rental.add.step0,
                infoStep: state.rental.add.step1,
                roomStep: state.rental.add.step2,
                priceStep: state.rental.add.step3,
            },
        },
    }),
    11: (state: any): any => {
        const enquiryState = {
            selection: {
                id: undefined,
                rentals: [],
            },
            ...state.enquiry,
            listActive: {
                filters: [[], [], [], [], [], [], enquiryStateByType.active],
                columns: { ...state.enquiry.columns },
                currentPage: 1,
                itemsPerPage: 30,
            },
            listArchived: {
                filters: [[], [], [], [], [], [], enquiryStateByType.archived],
                columns: { ...state.enquiry.columns },
                currentPage: 1,
                itemsPerPage: 30,
            },
            currentList: 'Active',
        };

        const { columns: _columns, ...enquiryRestState } = enquiryState;

        return {
            ...state,
            enquiry: {
                ...enquiryRestState,
            },
        };
    },
    12: (state: any): any => ({
        ...state,
        rental: {
            ...state.rental,
            planning: {
                page: 1,
                itemsPerPage: 30,
            },
        },
    }),
    13: (state: any): any => ({
        ...state,
        enquiry: {
            ...state.enquiry,
            listActive: {
                ...state.enquiry.listActive,
                filters: [[], [], [], [], [], [], enquiryStateByType.active],
            },
        },
    }),
    14: (state: any): any => ({
        ...state,
        booking: {
            ...state.booking,
            listArchived: {
                ...state.booking.listArchived,
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.archived, []],
            },
        },
    }),
    15: (state: any): any => ({
        ...state,
        booking: {
            ...state.booking,
            listAll: {
                currentPage: 1,
                itemsPerPage: 30,
                search: null,
                columns: {},
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.all, []],
            },
        },
    }),
    16: (state: any): any => ({
        ...state,
        enquiry: {
            ...state.enquiry,
            listAll: {
                currentPage: 1,
                itemsPerPage: 30,
                search: null,
                columns: {},
                filters: [[], [], [], [], [], [], enquiryStateByType.all],
            },
        },
        managerBooking: {
            ...state.managerBooking,
            listAll: {
                currentPage: 1,
                itemsPerPage: 30,
                search: null,
                columns: {},
                filters: [[], [], [], [], [], [], [], [], [], [], managerBookingStateByType.all],
            },
        },
    }),
};

export default migrations;
