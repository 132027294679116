import { createSelector } from '@reduxjs/toolkit';
import { MUISortOptions } from 'mui-datatables';
import { RootState } from 'store';
import { OrNull } from 'typings/shared';
import { ResourceListSelectors, StateResource } from 'typings/state';

type GetResourceState<ResourceState> = (_state: RootState) => ResourceState;

export default (getResourceState: GetResourceState<StateResource>): ResourceListSelectors => {
    const selectItemsPerPage = createSelector(
        getResourceState,
        (resourceState: StateResource): number => resourceState.list.itemsPerPage || 30,
    );

    const selectCurrentPage = createSelector(
        getResourceState,
        (resourceState: StateResource): number => resourceState.list.currentPage || 0,
    );

    const selectSearch = createSelector(
        getResourceState,
        (resourceState: StateResource): OrNull<string> => resourceState.list.search,
    );

    const selectFilters = createSelector(
        getResourceState,
        (resourceState: StateResource): Array<any> => resourceState.list.filters,
    );

    const selectSort = createSelector(
        getResourceState,
        (resourceState: StateResource): MUISortOptions | undefined => resourceState.list.sort,
    );

    const selectColumns = createSelector(
        getResourceState,
        (resourceState: StateResource): Record<string, boolean> => resourceState.list.columns,
    );

    const selectColumnOrder = createSelector(
        getResourceState,
        (resourceState: StateResource): Array<number> | undefined => resourceState.list.columnOrder,
    );

    return {
        selectItemsPerPage,
        selectCurrentPage,
        selectSearch,
        selectFilters,
        selectSort,
        selectColumns,
        selectColumnOrder,
    };
};
