import { paths, routes } from 'pages/enquiry/routes';
import api from 'resources/enquiry/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/enquiry/selectors';
import { actions } from 'store/enquiry/slice';
import { EnquiryApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<EnquiryApiItem, typeof paths, typeof api, typeof actions, typeof selectors, typeof routes> = {
    name: 'enquiry',
    translationKey: 'enquiry',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/enquiry')),
};

export default resource;
