import lazyWithRetry from 'services/lazyWithRetry';
import { Features, Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const basePath = '/manager-booking';

const ListPage = lazyWithRetry(() => import('./List'));
const AddPage = lazyWithRetry(() => import('./Add'));
const EditPage = lazyWithRetry(() => import('./Edit'));
const ShowPage = lazyWithRetry(() => import('./Show'));

export const paths = {
    LIST: `${basePath}/list`,
    ADD: `${basePath}/add`,
    ADD_FROM_PLANNING: `${basePath}/create-from-planning/:checkIn/:checkOut/:rentalId`,
    ADD_FROM_PRE_BOOKING: `${basePath}/create-from-pre-booking/:id`,
    EDIT: `${basePath}/:id/edit`,
    SHOW: `${basePath}/:id/show`,
};

export const routes: RouteCollection = {
    list: {
        exact: false,
        path: paths.LIST,
        component: ListPage,
        roles: [Roles.MANAGER, Roles.OWNER],
        features: [Features.RENTAL_MANAGER, Features.TEAM_OWNER],
    },
    add: {
        exact: false,
        path: paths.ADD,
        component: AddPage,
        roles: [Roles.MANAGER],
        features: [Features.RENTAL_MANAGER],
    },
    addFromPlanning: {
        exact: false,
        path: paths.ADD_FROM_PLANNING,
        component: AddPage,
        roles: [Roles.MANAGER],
        features: [Features.RENTAL_MANAGER],
    },
    addFromPreBooking: {
        exact: false,
        path: paths.ADD_FROM_PRE_BOOKING,
        component: AddPage,
        roles: [Roles.MANAGER],
        features: [Features.RENTAL_MANAGER],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: EditPage,
        roles: [Roles.MANAGER],
        features: [Features.RENTAL_MANAGER],
    },
    show: {
        exact: true,
        path: paths.SHOW,
        component: ShowPage,
        roles: [Roles.MANAGER, Roles.OWNER],
        features: [Features.RENTAL_MANAGER, Features.TEAM_OWNER],
    },
};
