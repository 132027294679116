import lazyWithRetry from 'services/lazyWithRetry';
import { Features, Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const SeasonListPage = lazyWithRetry(() => import('./List'));
const SeasonAddPage = lazyWithRetry(() => import('./Add'));
const SeasonEditPage = lazyWithRetry(() => import('./Edit'));
const SeasonShowPage = lazyWithRetry(() => import('./Show'));
const SeasonCopyPage = lazyWithRetry(() => import('./Copy'));

export const paths = {
    LIST: '/season',
    ADD: '/season/add',
    EDIT: '/season/:id/edit',
    SHOW: '/season/:id/show',
    COPY: '/season/:id/copy',
};

export const routes: RouteCollection = {
    list: {
        exact: true,
        path: paths.LIST,
        component: SeasonListPage,
        roles: [Roles.BASE_USER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    add: {
        exact: true,
        path: paths.ADD,
        component: SeasonAddPage,
        roles: [Roles.TRADER, Roles.WEBMASTER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    show: {
        exact: true,
        path: paths.SHOW,
        component: SeasonShowPage,
        roles: [Roles.BASE_USER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    copy: {
        exact: true,
        path: paths.COPY,
        component: SeasonCopyPage,
        roles: [Roles.TRADER, Roles.WEBMASTER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: SeasonEditPage,
        roles: [Roles.TRADER, Roles.WEBMASTER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
};
