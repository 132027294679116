import competitor from 'features/Competitor';
import managerBooking from 'features/ManagerBooking';
import owner from 'features/Owner';
import rental from 'features/Rental';
import provider from 'features/Provider';
import rentalAgency from 'features/RentalAgency';
import bed from 'features/Bed';
import booking from './booking';
import cancelReason from 'features/CancelReason';
import creditNote from 'features/CreditNote';
import customer from './customer';
import enquiry from './enquiry';
import furnishing from './furnishing';
import highlight from './highlight';
import locale from './locale';
import location from './location';
import manager from './manager';
import origin from './origin';
import partner from './partner';
import paymentMode from './paymentMode';
import poi from './poi';
import requestContact from './requestContact';
import roomType from './roomType';
import season from './season';
import team from './team';
import user from './user';

const resources = {
    bed,
    booking,
    competitor,
    customer,
    enquiry,
    furnishing,
    highlight,
    locale,
    location,
    manager,
    managerBooking,
    origin,
    owner,
    partner,
    paymentMode,
    poi,
    provider,
    rental,
    rentalAgency,
    roomType,
    season,
    team,
    user,
    creditNote,
    requestContact,
    cancelReason,
};

export default resources;
