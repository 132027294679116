import api from 'resources/locale/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/locale/selectors';
import { actions } from 'store/locale/slice';
import { LocaleApiItem } from 'typings/api';
import { Roles } from 'typings/enums';
import { Resource, RouteCollection } from 'typings/shared';

const paths = {
    LIST: '/locale',
    ADD: '/locale/add',
    EDIT: '/locale/:id/edit',
};

const routes: RouteCollection = {
    list: {
        exact: true,
        path: paths.LIST,
        component: lazyWithRetry(() => import('components/Security/NotGranted')),
        roles: [Roles.ADMIN],
    },
    add: {
        exact: true,
        path: paths.ADD,
        component: lazyWithRetry(() => import('components/Security/NotGranted')),
        roles: [Roles.ADMIN],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: lazyWithRetry(() => import('components/Security/NotGranted')),
        roles: [Roles.ADMIN],
    },
};

const resource: Resource<LocaleApiItem, typeof paths, typeof api, typeof actions, typeof selectors, typeof routes> = {
    name: 'locale',
    translationKey: 'locale',
    api,
    selectors,
    paths,
    routes,
    actions,
};

export default resource;
