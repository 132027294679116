import { AxiosPromise } from 'axios';
import { RentalApiItem } from 'features/Rental/types';
import { createResourceApi, DEFAULT_FETCHED_ITEMS_PER_AGE } from 'resources/ResourceApi';
import { normalizeUri, requestApi } from 'services/axios';
import { CollectionJsonLD } from 'typings/api';
import { AddAccessFormValues } from 'typings/forms';
import { OwnerApiItem } from '../types';

export const apiKey = 'owners';

const rentals = (
    owner: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<RentalApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri(apiKey, owner)}/rentals`,
        params: queryParameters,
    });
};

const grantAccess = (owner: string): AxiosPromise<OwnerApiItem> =>
    requestApi({
        url: `${normalizeUri(apiKey, owner)}/grant_access`,
        method: 'GET',
        showBackdrop: true,
    });

const revokeAccess = (owner: string): AxiosPromise<OwnerApiItem> =>
    requestApi({
        url: `${normalizeUri(apiKey, owner)}/revoke_access`,
        method: 'GET',
        showBackdrop: true,
    });

const addAccess = (owner: string, data: AddAccessFormValues): AxiosPromise<OwnerApiItem> =>
    requestApi({
        url: `${normalizeUri(apiKey, owner)}/add_access`,
        method: 'POST',
        data: {
            user: data,
        },
    });

export default { ...createResourceApi<OwnerApiItem>(apiKey), addAccess, grantAccess, rentals, revokeAccess };
