import { paths, routes } from 'features/CreditNote/pages/routes';
import api from './api';
import lazyWithRetry from 'services/lazyWithRetry';
import { selectors, actions } from 'features/CreditNote/store';
import { CreditNoteApiItem } from 'features/CreditNote/types';
import { Resource } from 'typings/shared';

export const translationKey = 'CreditNote';

const resource: Resource<CreditNoteApiItem, typeof paths, typeof api, typeof actions, typeof selectors, typeof routes> =
    {
        name: 'creditNote',
        translationKey,
        api,
        selectors,
        paths,
        routes,
        actions,
        component: lazyWithRetry(() => import('./pages')),
    };

export default resource;
