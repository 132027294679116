import { AxiosPromise } from 'axios';
import { createResourceApi } from 'resources/ResourceApi';
import { normalizeUri, requestApi, iriToId } from 'services/axios';
import { SeasonApiItem } from 'typings/api';

export const apiKey = 'seasons';

const copy = (to: string, from: string): AxiosPromise<SeasonApiItem> =>
    requestApi({
        url: `${normalizeUri(apiKey, to)}/copy/${iriToId(apiKey, from)}`,
        method: 'PATCH',
        data: {},
        headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/merge-patch+json',
        },
    });

export default {
    ...createResourceApi<SeasonApiItem>(apiKey),
    copy,
};
