import { createSelector } from '@reduxjs/toolkit';
import { ManagerBookingListType, ManagerBookingState } from 'features/ManagerBooking/types';
import { RootState } from 'store';
import createResourceListSelectors from 'store/ResourceListSelectors';
import { ResourceListSelectors, StateResource } from 'typings/state';

const getResourceState = (state: RootState): ManagerBookingState => state.managerBooking;

const managerBookingSelector: ResourceListSelectors = createResourceListSelectors(
    (state: RootState): StateResource => ({
        list: state.managerBooking[`list${state.managerBooking.currentList}` as const],
    }),
);

const selectCurrentList = createSelector(
    getResourceState,
    (state: ManagerBookingState): ManagerBookingListType => state.currentList,
);

const extraSelectors = {
    selectCurrentList,
};

const selectors = { ...managerBookingSelector, ...extraSelectors };
export default selectors;
