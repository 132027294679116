import lazyWithRetry from 'services/lazyWithRetry';
import { Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const basePath = '/bed';

const ListPage = lazyWithRetry(() => import('./List'));
const AddPage = lazyWithRetry(() => import('./Add'));
const EditPage = lazyWithRetry(() => import('./Edit'));

export const paths = {
    LIST: `${basePath}/list`,
    ADD: `${basePath}/add`,
    EDIT: `${basePath}/:id/edit`,
};

export const routes: RouteCollection = {
    list: {
        exact: true,
        path: paths.LIST,
        component: ListPage,
        roles: [Roles.ADMIN],
    },
    add: {
        exact: true,
        path: paths.ADD,
        component: AddPage,
        roles: [Roles.ADMIN],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: EditPage,
        roles: [Roles.ADMIN],
    },
};
