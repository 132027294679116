export enum RentalType {
    RENTAL_TYPE_VILLA = 'villa',
    RENTAL_TYPE_APARTMENT = 'apartment',
}

export enum BathroomType {
    BATHROOM_TYPE_JOINED = 'joined',
    BATHROOM_TYPE_SEPARATED = 'separated',
    BATHROOM_TYPE_SHARED = 'shared',
}
