import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { MUISortOptions } from 'mui-datatables';
import { resourceInitialState } from 'store/ResourceSlice';
import { OrNull } from 'typings/shared';
import { AccountingState } from 'features/Accounting/types';

const { list: listState } = resourceInitialState();

export const initialState: AccountingState = {
    listBank: listState,
    listCommission: listState,
    listSales: listState,
    period: {
        start: DateTime.now().set({ day: 1, month: 10 }).minus({ years: 1 }).toJSON(),
        end: DateTime.now().set({ day: 30, month: 9 }).toJSON(),
    },
};

const slice = createSlice({
    name: 'accounting',
    initialState,
    reducers: {
        setCurrentPage: (state: AccountingState, action: PayloadAction<number>): void => {
            if (undefined !== state.currentList) {
                state[`list${state.currentList}` as const].currentPage = action.payload;
            }
        },
        setItemsPerPage: (state: AccountingState, action: PayloadAction<number>): void => {
            if (undefined !== state.currentList) {
                if (state[`list${state.currentList}` as const].itemsPerPage !== action.payload) {
                    state[`list${state.currentList}` as const].currentPage = 1;
                }
                state[`list${state.currentList}` as const].itemsPerPage = action.payload;
            }
        },
        setSearch: (state: AccountingState, action: PayloadAction<OrNull<string>>): void => {
            if (undefined !== state.currentList) {
                if (state[`list${state.currentList}` as const].search !== action.payload) {
                    state[`list${state.currentList}` as const].currentPage = 1;
                }
                state[`list${state.currentList}` as const].search = action.payload;
            }
        },
        setFilters: (state: AccountingState, action: PayloadAction<Array<any>>): void => {
            if (undefined !== state.currentList) {
                if (state[`list${state.currentList}` as const].filters !== action.payload) {
                    state[`list${state.currentList}` as const].currentPage = 1;
                }
                state[`list${state.currentList}` as const].filters = action.payload;
            }
        },
        setSort: (state: AccountingState, action: PayloadAction<MUISortOptions>): void => {
            if (undefined !== state.currentList) {
                state[`list${state.currentList}` as const].sort = action.payload;
            }
        },
        setColumnVisibility: (
            state: AccountingState,
            action: PayloadAction<{ column: string; visible: boolean }>,
        ): void => {
            if (undefined !== state.currentList) {
                state[`list${state.currentList}` as const].columns[action.payload.column] = action.payload.visible;
            }
        },
        setCurrentList: (state: AccountingState, action: PayloadAction<AccountingState['currentList']>): void => {
            state.currentList = action.payload;
        },
        setPeriod: (state, action: PayloadAction<{ start: OrNull<string>; end: OrNull<string> }>): void => {
            state.period = {
                start: action.payload.start ?? DateTime.now().set({ day: 1, month: 10 }).minus({ years: 1 }).toJSON(),
                end: action.payload.end ?? DateTime.now().set({ day: 30, month: 9 }).toJSON(),
            };
        },
    },
});

export const accounting = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
