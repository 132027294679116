import { createSelector } from '@reduxjs/toolkit';
import {
    RentalAddDeclaLocStepState,
    RentalAddInfoStepState,
    RentalAddPriceStepState,
    RentalAddRoomStepState,
    RentalAddState,
    RentalAdvancedSearchState,
    RentalPlanningState,
    RentalState,
} from 'features/Rental/types';
import { RootState } from 'store';
import createResourceListSelectors from 'store/ResourceListSelectors';

const getResourceState = (state: RootState): RentalState => state.rental;

const selectFormAddStep = createSelector(getResourceState, (state: RentalState): number => state.add.currentStep);

const selectFormAddState = createSelector(getResourceState, (state: RentalState): RentalAddState => state.add);

const selectAddDeclalocStepState = createSelector(
    getResourceState,
    (state: RentalState): RentalAddDeclaLocStepState => state.add.declalocStep,
);

const selectAddInfoStepState = createSelector(
    getResourceState,
    (state: RentalState): RentalAddInfoStepState => state.add.infoStep,
);
const selectAddRoomStepState = createSelector(
    getResourceState,
    (state: RentalState): RentalAddRoomStepState => state.add.roomStep,
);

const selectAddPriceStepState = createSelector(
    getResourceState,
    (state: RentalState): RentalAddPriceStepState => state.add.priceStep,
);

const selectLastStep = createSelector(getResourceState, (state: RentalState): boolean => state.add.lastStep);

const selectAdvancedSearchFilter = createSelector(
    getResourceState,
    (resourceState: RentalState): RentalAdvancedSearchState => resourceState.advancedSearch,
);

const selectListTab = createSelector(getResourceState, (state: RentalState): number => state.listTab);

const selectPlanning = createSelector(getResourceState, (state: RentalState): RentalPlanningState => state.planning);

const extraSelectors = {
    selectAdvancedSearchFilter,
    selectFormAddStep,
    selectFormAddState,
    selectAddDeclalocStepState,
    selectAddInfoStepState,
    selectAddRoomStepState,
    selectAddPriceStepState,
    selectLastStep,
    selectListTab,
    selectPlanning,
};

const selectors = { ...createResourceListSelectors(getResourceState), ...extraSelectors };

export default selectors;
