import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { StateResource } from 'typings/state';

export const initialState: StateResource = resourceInitialState();

const slice = createResourceSlice({
    name: 'owner',
    initialState,
});

export const owner = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
