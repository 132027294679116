/* eslint-disable @typescript-eslint/naming-convention */
import { Container, createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import Snackbar from 'components/DataDisplay/Snackbar';
import FloatingSelection from 'components/Entities/Enquiry/FloatingSelection';
import FileDownloader from 'components/Layout/FileDownloader';
import { useMercure } from 'hooks/useMercure';
import { paths, routes } from 'pages/routes';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import './App.css';
import './services/i18n';

const App: React.FC = () => {
    const queryClient = new QueryClient();
    queryClient.setDefaultOptions({
        queries: { refetchOnWindowFocus: false, staleTime: 60 * 60 * 1000, cacheTime: 60 * 60 * 1000, retry: 1 },
    });
    useMercure(queryClient);
    const defaultTheme = createTheme();
    const theme = createTheme({
        palette: {
            type: 'dark',
            primary: {
                light: '#d05ce3',
                main: '#dbabff',
                dark: '#6a0080',
                contrastText: '#fff',
            },
            secondary: {
                light: '#6effff',
                main: '#00e5ff',
                dark: '#00b2cc',
                contrastText: '#000',
            },
        },
        overrides: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            MUIDataTableBodyRow: {
                responsiveStacked: {
                    [defaultTheme.breakpoints.down('sm')]: {
                        borderTop: 'solid 2px rgba(255, 255, 255, 1)',
                        borderBottom: 'solid 2px rgba(255, 255, 255, 1)',
                    },
                },
                responsiveSimple: {
                    [defaultTheme.breakpoints.down('xs')]: {
                        borderTop: 'solid 2px rgba(255, 255, 255, 1)',
                        borderBottom: 'solid 2px rgba(255, 255, 255, 1)',
                    },
                },
            },
            MUIDataTableToolbar: {
                filterPaper: {
                    overflow: 'visible',
                },
            },
            MuiCssBaseline: {
                '@global': {
                    '*::-webkit-scrollbar': {
                        width: '8px',
                        height: '8px',
                    },
                    '*::-webkit-scrollbar-track': {
                        background: 'inherit',
                        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    },
                    '*::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(219, 171, 255, 0.3)',
                        borderRadius: '20px',
                        border: 'rgba(219, 171, 255, 0.3)',
                    },
                    '*::-webkit-scrollbar-corner': {
                        background: 'inherit',
                    },
                },
            },
        },
    });

    useEffect(() => {
        if (process.env.NODE_ENV !== 'production') {
            document.title = `[${process.env.NODE_ENV}] - Orion`;
        }
    }, []);

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <ThemeProvider theme={theme}>
                    <SCThemeProvider theme={theme}>
                        <CssBaseline />
                        <Container
                            maxWidth={false}
                            disableGutters
                            style={{
                                display: 'flex',
                                width: '100vw',
                                height: '100%',
                                maxWidth: '100%',
                                minHeight: '100vh',
                            }}
                        >
                            <FloatingSelection />
                            <Snackbar />
                            <FileDownloader />
                            <Switch>
                                {routes.map(({ exact, path, component: Component, layout: Layout }, index) => (
                                    <Route
                                        key={index}
                                        exact={exact}
                                        path={path}
                                        render={(props): React.ReactElement => (
                                            <Layout>
                                                <Component {...props} />
                                            </Layout>
                                        )}
                                    />
                                ))}
                                <Redirect to={paths.dashboard.DASHBOARD_INDEX} />
                            </Switch>
                        </Container>
                    </SCThemeProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </>
    );
};

export default App;
