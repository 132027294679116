import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import createResourceListSelectors from 'store/ResourceListSelectors';
import { BookingListType, OrNull } from 'typings/shared';
import { BookingAddBill, BookingAddInfos, BookingState, ResourceListSelectors, StateResource } from 'typings/state';

const getResourceState = (state: RootState): BookingState => state.booking;

const bookingSelectors: ResourceListSelectors = createResourceListSelectors(
    (state: RootState): StateResource => ({
        list: state.booking[`list${state.booking.currentList}` as const],
    }),
);

const selectFormAddCurrentStep = createSelector(
    getResourceState,
    (state: BookingState): number => state.add.currentStep,
);

const selectFormAddInfosStep = createSelector(
    getResourceState,
    (state: BookingState): BookingAddInfos => state.add.infosStep,
);

const selectFormAddBillChoiceStep = createSelector(
    getResourceState,
    (state: BookingState): boolean => state.add.customerBilled,
);

const selectFormAddSBillStep = createSelector(
    getResourceState,
    (state: BookingState): BookingAddBill => state.add.bill,
);

const selectCurrentList = createSelector(getResourceState, (state: BookingState): BookingListType => state.currentList);

const selectFromEnquiry = createSelector(getResourceState, (state: BookingState): OrNull<string> => state.add.enquiry);

const extraSelectors = {
    selectFormAddCurrentStep,
    selectFormAddInfosStep,
    selectFormAddBillChoiceStep,
    selectFormAddSBillStep,
    selectCurrentList,
    selectFromEnquiry,
};

const selectors = { ...bookingSelectors, ...extraSelectors };
export default selectors;
