import { createSelector } from '@reduxjs/toolkit';
import { format, set } from 'date-fns';
import { OutputSelector } from 'reselect';
import { RootState } from 'store';
import createResourceListSelectors from 'store/ResourceListSelectors';
import { ResourceListSelectors, StateResource } from 'typings/state';
import { AccountingState } from 'features/Accounting/types';

const getResourceState = (state: RootState): AccountingState => state.accounting;

const accountingSelectors: ResourceListSelectors = createResourceListSelectors(
    (state: RootState): StateResource => ({
        list: state.accounting[`list${state.accounting.currentList || 'Bank'}` as const],
    }),
);

const selectCurrentList = createSelector(
    getResourceState,
    (state: AccountingState): AccountingState['currentList'] => state.currentList,
);

const selectCustomListColumns = (
    list: Exclude<AccountingState['currentList'], undefined>,
): OutputSelector<
    [typeof getResourceState],
    Record<string, boolean>,
    (_state: AccountingState) => Record<string, boolean>
> =>
    createSelector(
        getResourceState,
        (state: AccountingState): Record<string, boolean> => state[`list${list}` as const].columns,
    );

const selectPeriod = createSelector(getResourceState, (state: AccountingState): { start: string; end: string } => {
    const today = new Date();
    const period = state.period ? { ...state.period } : { start: null, end: null };
    period.start =
        period.start ?? format(set(new Date(), { date: 1, month: 9, year: today.getFullYear() - 1 }), 'yyyy-MM-dd');
    period.end = period.end ?? format(set(new Date(), { date: 30, month: 8, year: today.getFullYear() }), 'yyyy-MM-dd');

    return state.period || period;
});

const selectors: ResourceListSelectors & {
    selectCustomListColumns: typeof selectCustomListColumns;
    selectCurrentList: typeof selectCurrentList;
    selectPeriod: typeof selectPeriod;
} = { ...accountingSelectors, selectCustomListColumns, selectCurrentList, selectPeriod };

export default selectors;
