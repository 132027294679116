import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { createResourceApi, DEFAULT_FETCHED_ITEMS_PER_AGE } from 'resources/ResourceApi';
import { normalizeUri, requestApi } from 'services/axios';
import {
    AdditionalLineApiItem,
    ApiPushItem,
    BookingApiItem,
    BookingInvoiceApiItem,
    BookingLineApiItem,
    BookingPaymentApiItem,
    BookingQuoteApiItem,
    BookingRegularisationApiItem,
    CollectionJsonLD,
    MediaObjectApiItem,
} from 'typings/api';
import { OwnerPaymentFormValues } from 'typings/forms';
import { BookingCancelItem } from 'typings/resources';
import { CreditNoteApiItem } from 'features/CreditNote/types';

const bookingLines = (
    booking: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<BookingLineApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('bookings', booking)}/booking_lines`,
        params: queryParameters,
    });
};

const additionalLines = (
    booking: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<AdditionalLineApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('bookings', booking)}/additional_lines`,
        params: queryParameters,
    });
};

const bookingInvoices = (
    booking: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<BookingInvoiceApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('bookings', booking)}/invoices`,
        params: queryParameters,
        headers: { 'with-archived': '1' },
    });
};

const bookingQuotes = (
    booking: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<BookingInvoiceApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('bookings', booking)}/quotes`,
        params: queryParameters,
        headers: { 'with-archived': '1' },
    });
};

const bookingPayments = (
    booking: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<BookingPaymentApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('bookings', booking)}/payments`,
        params: queryParameters,
    });
};

const creditNote = (id: string): AxiosPromise<CreditNoteApiItem> =>
    requestApi({
        url: normalizeUri('credit_notes', id),
    });

const bookingLinePrice = (
    booking: string,
    bookingLine: Omit<ApiPushItem<BookingLineApiItem>, 'nightCount' | 'baseWeeklyPrice' | 'billedAmount'>,
): AxiosPromise<Array<BookingLineApiItem>> =>
    requestApi({
        url: `/booking_lines/prices`,
        method: 'POST',
        data: {
            ...bookingLine,
            booking: normalizeUri('bookings', booking),
        },
    });

const createInvoice = (bookingId: string): AxiosPromise<BookingInvoiceApiItem> =>
    requestApi({
        url: `${normalizeUri('bookings', bookingId)}/create-invoice`,
        method: 'GET',
    });

const createQuote = (bookingId: string): AxiosPromise<BookingQuoteApiItem> =>
    requestApi({
        url: `${normalizeUri('bookings', bookingId)}/create-quote`,
        method: 'GET',
    });

const ownerPayment = (
    bookingId: string,
    data: OwnerPaymentFormValues,
    options?: AxiosRequestConfig,
): AxiosPromise<BookingApiItem> =>
    requestApi({
        url: `${normalizeUri('bookings', bookingId)}/owner-payment`,
        method: 'PATCH',
        data,
        ...options,
        headers: {
            ...options?.headers,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/merge-patch+json',
        },
    });

const partnerPayment = (
    bookingId: string,
    data: OwnerPaymentFormValues,
    options?: AxiosRequestConfig,
): AxiosPromise<BookingApiItem> =>
    requestApi({
        url: `${normalizeUri('bookings', bookingId)}/partner-payment`,
        method: 'PATCH',
        data,
        ...options,
        headers: {
            ...options?.headers,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/merge-patch+json',
        },
    });

const cancelBooking = (data: BookingCancelItem): AxiosPromise<BookingApiItem> =>
    requestApi({
        url: `${normalizeUri('bookings', data.booking)}/cancel`,
        method: 'PATCH',
        headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/merge-patch+json',
        },
        data,
    });

const bookingMediaObjects = (
    booking: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<MediaObjectApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('bookings', booking)}/media_objects`,
        params: queryParameters,
        headers: { 'with-archived': '1' },
    });
};

const bookingMediaObjectsExport = (booking: string, mediaObject: string): AxiosPromise<File> =>
    requestApi({
        url: `${normalizeUri('bookings', booking)}/${mediaObject}/export`,
        responseType: 'blob',
        headers: { 'with-archived': '1' },
    });

const bookingRegularisations = (
    booking: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<BookingRegularisationApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('bookings', booking)}/regularisations`,
        params: queryParameters,
    });
};

const bookingForceConfirmed = (booking: string): AxiosPromise<void> =>
    requestApi({
        url: `${normalizeUri('bookings', booking)}/force-confirmed`,
        method: 'PATCH',
        headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/merge-patch+json',
        },
        data: {},
    });

export default {
    ...createResourceApi<BookingApiItem>('bookings'),
    bookingLines,
    additionalLines,
    bookingLinePrice,
    bookingInvoices,
    bookingQuotes,
    bookingPayments,
    creditNote,
    createInvoice,
    createQuote,
    ownerPayment,
    partnerPayment,
    cancelBooking,
    bookingMediaObjects,
    bookingMediaObjectsExport,
    bookingRegularisations,
    bookingForceConfirmed,
};
