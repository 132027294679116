import lazyWithRetry from 'services/lazyWithRetry';
import { actions, selectors } from './store';
import { Resource } from 'typings/shared';
import api from './api';
import { paths, routes } from './pages/routes';
import { RentalAgencyApiItem } from './types';

export const translationKey = 'RentalAgency';

const resource: Resource<
    RentalAgencyApiItem,
    typeof paths,
    typeof api,
    typeof actions,
    typeof selectors,
    typeof routes
> = {
    name: 'rentalAgency',
    translationKey,
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('./pages')),
};

export default resource;
