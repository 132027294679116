import { AxiosPromise } from 'axios';
import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { ProviderApiItem } from 'features/Provider/types';
import {
    PointOfInterestRentalApiItem,
    PriceApiItem,
    RentalAdvancedSearchState,
    RentalApiItem,
    RentalLeaseApiItem,
    RentalPictureApiItem,
    RentalSeasonApiItem,
    RentalShare,
    RentalSyncFormValues,
    RoomApiItem,
    SpecialOfferApiItem,
} from 'features/Rental/types';
import { UnavailabilityApiItem } from 'features/Unavailability/types';
import { createResourceApi, DEFAULT_FETCHED_ITEMS_PER_AGE } from 'resources/ResourceApi';
import { normalizeUri, requestApi } from 'services/axios';
import {
    BedroomRangeApi,
    CollectionJsonLD,
    HighlightApiItem,
    ManagerOccupancyStatsApi,
    MandateApiItem,
    OccupancyStatsApi,
    PriceRangeApi,
} from 'typings/api';
import { OrNull } from 'typings/shared';

export const apiKey = 'rentals';

const buildParameters = (searchData: RentalAdvancedSearchState, page: number, itemsPerPage: number): any => ({
    price: searchData.price,
    searchPeriod: {
        periodStart: format(parseISO(searchData.periodStart || ''), 'yyyy-MM-dd'),
        periodEnd: format(parseISO(searchData.periodEnd || ''), 'yyyy-MM-dd'),
        minBookingDays:
            searchData.fixedPeriod ?? true
                ? differenceInCalendarDays(parseISO(searchData.periodEnd || ''), parseISO(searchData.periodStart || ''))
                : searchData.minBookingDays,
    },
    bedroomCount: {
        gte: searchData.bedrooms,
    },
    capacity: {
        gte: searchData.capacity,
    },
    location: searchData.location,
    page,
    perPage: itemsPerPage,
});

const priceRange = (): AxiosPromise<PriceRangeApi> =>
    requestApi({
        url: '/rentals/aggregate/price_range',
    });

const bedroomRange = (): AxiosPromise<BedroomRangeApi> =>
    requestApi({
        url: '/rentals/aggregate/bedroom_range',
    });

const rentalPriceRange = (
    rental: string,
    searchData: Pick<RentalAdvancedSearchState, 'periodStart' | 'periodEnd' | 'bedrooms'>,
): AxiosPromise<PriceRangeApi> =>
    requestApi({
        url: `${normalizeUri(apiKey, rental)}/price_range`,
        params: {
            periodStart: format(parseISO(searchData.periodStart || ''), 'yyyy-MM-dd'),
            periodEnd: format(parseISO(searchData.periodEnd || ''), 'yyyy-MM-dd'),
            bedrooms: searchData.bedrooms,
        },
    });

const advancedSearch = (
    searchData: RentalAdvancedSearchState,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<RentalApiItem>> => {
    const queryParameters = buildParameters(searchData, page, itemsPerPage);
    return requestApi({
        url: `/rentals`,
        params: queryParameters,
    });
};

const searchRentals = async (
    searchData: RentalAdvancedSearchState,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): Promise<CollectionJsonLD<RentalApiItem>> => {
    const queryParameters = buildParameters(searchData, page, itemsPerPage);
    const response = await requestApi({
        url: `/rentals`,
        params: queryParameters,
    });
    return response.data as CollectionJsonLD<RentalApiItem>;
};

const unavailabilities = (
    rental: string,
    filterData: Record<string, any> & { periodStart?: OrNull<string>; periodEnd?: OrNull<string> },
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<UnavailabilityApiItem>> => {
    const { page: _page, periodStart, periodEnd, ...filters } = filterData;
    const queryParameters = {
        periodEnd: {
            after: periodStart,
        },
        periodStart: {
            before: periodEnd,
        },
        perPage: itemsPerPage,
        ...filters,
        page,
    };
    return requestApi({
        url: `${normalizeUri(apiKey, rental)}/unavailabilities`,
        params: queryParameters,
    });
};

const unavailability = (id: string): AxiosPromise<UnavailabilityApiItem> =>
    requestApi({
        url: normalizeUri('unavailabilities', id),
    });

const prices = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<PriceApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri(apiKey, rental)}/prices`,
        params: queryParameters,
    });
};

const pictures = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<RentalPictureApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri(apiKey, rental)}/pictures`,
        params: queryParameters,
    });
};

const rooms = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<RoomApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri(apiKey, rental)}/rooms`,
        params: queryParameters,
    });
};

const specialOffers = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<SpecialOfferApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri(apiKey, rental)}/special_offers`,
        params: queryParameters,
    });
};

const rentalSeasons = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<RentalSeasonApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri(apiKey, rental)}/rental_seasons`,
        params: queryParameters,
    });
};

const highlights = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<HighlightApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri(apiKey, rental)}/highlights`,
        params: queryParameters,
    });
};

const pois = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<PointOfInterestRentalApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri(apiKey, rental)}/point_of_interests`,
        params: queryParameters,
    });
};

const rentalPicturesZip = (rentalSlug: string): AxiosPromise<File> =>
    requestApi({
        url: `/rentals/pictures/${rentalSlug}.zip`,
        responseType: 'blob',
    });

const isAvailable = (id: string, periodStart: string, periodEnd: string): AxiosPromise<{ available: boolean }> =>
    requestApi({
        url: `${normalizeUri(apiKey, id)}/is_available/${periodStart}/${periodEnd}`,
    });

const mandates = (
    rental: string,
    page: number,
    filterData: { expiresAfter?: OrNull<string> },
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<MandateApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
        expiresAt: {},
    };
    if (filterData?.expiresAfter) {
        queryParameters.expiresAt = {
            after: filterData?.expiresAfter,
        };
    }
    return requestApi({
        url: `${normalizeUri(apiKey, rental)}/mandates`,
        params: queryParameters,
    });
};

const rentalMandateExport = (mandate: string): AxiosPromise<File> =>
    requestApi({
        url: `${normalizeUri('mandates', mandate)}/export`,
        responseType: 'blob',
    });

const statsOccupancy = (
    rental: string,
    searchData: Pick<RentalAdvancedSearchState, 'periodStart' | 'periodEnd'>,
): AxiosPromise<OccupancyStatsApi> => {
    const periodStart = format(parseISO(searchData.periodStart || ''), 'yyyy-MM-dd');
    const periodEnd = format(parseISO(searchData.periodEnd || ''), 'yyyy-MM-dd');
    return requestApi({
        url: `${normalizeUri(apiKey, rental)}/stats/occupancy/${periodStart}/${periodEnd}`,
    });
};

const statsManagerOccupancy = (
    rental: string,
    searchData: Pick<RentalAdvancedSearchState, 'periodStart' | 'periodEnd'>,
): AxiosPromise<ManagerOccupancyStatsApi> => {
    const periodStart = format(parseISO(searchData.periodStart || ''), 'yyyy-MM-dd');
    const periodEnd = format(parseISO(searchData.periodEnd || ''), 'yyyy-MM-dd');
    return requestApi({
        url: `${normalizeUri(apiKey, rental)}/stats/manager_occupancy/${periodStart}/${periodEnd}`,
    });
};

const leases = (
    rental: string,
    rentalAgency: OrNull<string>,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
    filters: Record<string, any> = {},
): AxiosPromise<CollectionJsonLD<RentalLeaseApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
        rentalAgency: rentalAgency ?? {},
        ...filters,
    };
    return requestApi({
        url: `${normalizeUri(apiKey, rental)}/rental_leases`,
        params: queryParameters,
    });
};

const syncSources = (
    declaLoc: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<RentalApiItem>> => {
    const queryParameters = {
        declaLoc,
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: '/rentals',
        params: queryParameters,
        headers: {
            'WITH-SHARED': '1',
        },
    });
};

const syncFrom = (rental: string, data: RentalSyncFormValues): AxiosPromise<RentalApiItem> =>
    requestApi({
        url: `${normalizeUri(apiKey, rental)}/sync_from`,
        method: 'PATCH',
        data,
        headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/merge-patch+json',
        },
    });

const providers = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<ProviderApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('rentals', rental)}/providers`,
        params: queryParameters,
    });
};

const share = (data: Array<RentalShare>): AxiosPromise<any> =>
    requestApi({
        url: `${apiKey}/share`,
        method: 'POST',
        data: {
            shares: data,
        },
    });

export default {
    ...createResourceApi<RentalApiItem>(apiKey),
    priceRange,
    rentalPriceRange,
    bedroomRange,
    advancedSearch,
    unavailability,
    unavailabilities,
    highlights,
    pois,
    prices,
    providers,
    pictures,
    rooms,
    specialOffers,
    rentalSeasons,
    rentalPicturesZip,
    searchRentals,
    isAvailable,
    mandates,
    rentalMandateExport,
    statsOccupancy,
    statsManagerOccupancy,
    syncFrom,
    syncSources,
    leases,
    share,
};
