import { paths, routes } from './pages/routes';
import api from './api';
import lazyWithRetry from 'services/lazyWithRetry';
import { actions, selectors } from 'features/CancelReason/store';
import { CancelReasonApiItem } from 'features/CancelReason/types';
import { Resource } from 'typings/shared';

export const translationKey = 'CancelReason';

const resource: Resource<
    CancelReasonApiItem,
    typeof paths,
    typeof api,
    typeof actions,
    typeof selectors,
    typeof routes
> = {
    name: 'cancelReason',
    translationKey,
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('./pages')),
};

export default resource;
