import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import createResourceListSelectors from 'store/ResourceListSelectors';
import { TeamApiItem } from 'typings/api';
import { OrNull } from 'typings/shared';
import { ResourceListSelectors, TeamState } from 'typings/state';

const getResourceState = (state: RootState): TeamState => state.team;
const selectors: ResourceListSelectors = createResourceListSelectors(getResourceState);

const selectMyTeam = createSelector(getResourceState, (teamState: TeamState): OrNull<TeamApiItem> => teamState.myTeam);

export default { ...selectors, selectMyTeam };
