import { PayloadAction } from '@reduxjs/toolkit';
import {
    RentalAddDeclaLocStepState,
    RentalAddInfoStepState,
    RentalAddPriceStepState,
    RentalAddRoomStepState,
    RentalAddState,
    RentalAdvancedSearchState,
    RentalPlanningState,
    RentalState,
} from 'features/Rental/types';
import { RentalType } from 'features/Rental/types/enums';
import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';

export const MAX_STEP_FORM_RENTAL_ADD = 4;

const initialRentalAddDeclalocStep: RentalAddDeclaLocStepState = {
    declaLoc: '',
};

const initialRentalAddInfoStep: RentalAddInfoStepState = {
    title: '',
    rentalType: RentalType.RENTAL_TYPE_VILLA,
    location: '',
    owner: '',
    manager: '',
    managed: undefined,
};

const initialRentalAddRoomStep: RentalAddRoomStepState = {
    rooms: {},
    bathroomCount: 1,
    bedroomCount: 3,
};

const initialRentalAddPriceStep: RentalAddPriceStepState = {
    prices: [],
};

const initialRentalAddState: RentalAddState = {
    currentStep: 0,
    lastStep: false,
    declalocStep: initialRentalAddDeclalocStep,
    infoStep: initialRentalAddInfoStep,
    roomStep: initialRentalAddRoomStep,
    priceStep: initialRentalAddPriceStep,
};

export const initialAdvancedSearchState: RentalAdvancedSearchState = {
    bedrooms: 1,
    capacity: 1,
    fixedPeriod: true,
    minBookingDays: 7,
    location: [],
    price: {
        min: 0,
        max: 1000000,
    },
    periodStart: null,
    periodEnd: null,
    page: 1,
};

export const initialPlanningState: RentalPlanningState = {
    page: 1,
    itemsPerPage: 30,
};

export const initialState: RentalState = {
    ...resourceInitialState(),
    advancedSearch: initialAdvancedSearchState,
    add: initialRentalAddState,
    listTab: 0,
    planning: initialPlanningState,
};

const slice = createResourceSlice({
    name: 'rental',
    initialState,
    reducers: {
        setAdvancedSearchFilters: (state, action: PayloadAction<RentalAdvancedSearchState>): void => {
            state.advancedSearch = action.payload;
        },
        setStep: (state, action: PayloadAction<number>): void => {
            state.add.currentStep = action.payload;
        },
        nextStep: (state): void => {
            let next = ++state.add.currentStep;
            next > MAX_STEP_FORM_RENTAL_ADD ? (next = MAX_STEP_FORM_RENTAL_ADD) : null;
            state.add.currentStep = next;
            state.add.lastStep = MAX_STEP_FORM_RENTAL_ADD === state.add.currentStep;
        },
        prevStep: (state): void => {
            let prev = --state.add.currentStep;
            prev < 0 ? (prev = 0) : null;
            state.add.currentStep = prev;
            state.add.lastStep = MAX_STEP_FORM_RENTAL_ADD === state.add.currentStep;
        },
        resetAddState: (state, _action: PayloadAction<boolean>): void => {
            state.add = initialRentalAddState;
        },
        setAddDeclaLocStep: (state, action: PayloadAction<RentalAddDeclaLocStepState>): void => {
            state.add.declalocStep = action.payload;
        },
        setAddInfoStep: (state, action: PayloadAction<RentalAddInfoStepState>): void => {
            state.add.infoStep = action.payload;
        },
        setAddRoomStep: (state, action: PayloadAction<RentalAddRoomStepState>): void => {
            state.add.roomStep = action.payload;
        },
        changeTab: (state, action: PayloadAction<number>): void => {
            state.listTab = action.payload;
        },
        setPlanning: (state, action: PayloadAction<RentalPlanningState>): void => {
            state.planning = action.payload;
        },
    },
});

export const rental = slice.reducer;
export const actions = slice.actions;
