import { PayloadAction } from '@reduxjs/toolkit';
import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { TeamApiItem } from 'typings/api';
import { TeamState } from 'typings/state';

export const initialState: TeamState = { ...resourceInitialState(), myTeam: null };

const slice = createResourceSlice({
    name: 'team',
    initialState,
    reducers: {
        connect: (state, action: PayloadAction<TeamApiItem>): void => {
            state.myTeam = action.payload;
        },
        disconnect: (state): void => {
            state.myTeam = null;
        },
    },
});

export const team = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
