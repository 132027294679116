import AuthenticatedLayout from 'components/Security/AuthenticatedLayout';
import lazyWithRetry from 'services/lazyWithRetry';
import { Features, Roles } from 'typings/enums';
import { RouteWithLayout } from 'typings/shared';

const basePath = '/accounting';

const AccountingBankPage = lazyWithRetry(() => import('./Bank'));
const AccountingSalesPage = lazyWithRetry(() => import('./Sales'));
const AccountingCommissionPage = lazyWithRetry(() => import('./Commission'));
const AccountingTouristTaxPage = lazyWithRetry(() => import('./TouristTax'));

export const paths = {
    ACCOUNTING_BANK: `${basePath}/bank`,
    ACCOUNTING_SALES: `${basePath}/sales`,
    ACCOUNTING_COMMISSION: `${basePath}/commission`,
    ACCOUNTING_TOURIST_TAX: `${basePath}/tourist-tax`,
};

export const accountingRoutes: RouteWithLayout[] = [
    {
        exact: true,
        path: paths.ACCOUNTING_BANK,
        component: AccountingBankPage,
        layout: AuthenticatedLayout,
        roles: [Roles.ACCOUNTING],
        features: [Features.RENTAL_AGENCY],
    },
    {
        exact: true,
        path: paths.ACCOUNTING_SALES,
        component: AccountingSalesPage,
        layout: AuthenticatedLayout,
        roles: [Roles.ACCOUNTING],
        features: [Features.RENTAL_AGENCY],
    },
    {
        exact: true,
        path: paths.ACCOUNTING_COMMISSION,
        component: AccountingCommissionPage,
        layout: AuthenticatedLayout,
        roles: [Roles.ACCOUNTING],
        features: [Features.RENTAL_AGENCY],
    },
    {
        exact: true,
        path: paths.ACCOUNTING_TOURIST_TAX,
        component: AccountingTouristTaxPage,
        layout: AuthenticatedLayout,
        roles: [Roles.ACCOUNTING],
        features: [Features.RENTAL_AGENCY],
    },
];
