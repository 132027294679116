import lazyWithRetry from 'services/lazyWithRetry';
import { Features, Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const RentalListPage = lazyWithRetry(() => import('./List'));
const RentalAddPage = lazyWithRetry(() => import('./Add'));
const RentalEditPage = lazyWithRetry(() => import('./Edit'));
const RentalShowPage = lazyWithRetry(() => import('./Show'));
const RentalOwnerShowPage = lazyWithRetry(() => import('./OwnerShow'));
const RentalProviderShowPage = lazyWithRetry(() => import('./ProviderShow'));
const RentalSyncPage = lazyWithRetry(() => import('./Sync'));
const RentalPlanningPage = lazyWithRetry(() => import('./Planning'));
const RentalSharePlanningPage = lazyWithRetry(() => import('./SharePlanning'));
const RentalShareSinglePlanningPage = lazyWithRetry(() => import('./ShareSinglePlanning'));

export const paths = {
    LIST: '/rental/list',
    SHOW: '/rental/:id/show',
    OWNER_SHOW: '/rental/:id/owner',
    PROVIDER_SHOW: '/rental/:id/provider',
    ADD: '/rental/add',
    EDIT: '/rental/:id/edit',
    SYNC: '/rental/:id/sync',
    ADD_SYNC_STEP: '/rental/add/sync',
    ADD_INFO_STEP: '/rental/add/info',
    ADD_ROOM_STEP: '/rental/add/rooms',
    ADD_PRICE_STEP: '/rental/add/prices',
    PLANNING: '/rental/planning',
    SHARE_PLANNING: '/rental/share-planning',
    SHARE_SINGLE_PLANNING: '/rental/:id/share-planning',
};

export const routes: RouteCollection = {
    list: {
        exact: false,
        path: paths.LIST,
        component: RentalListPage,
        roles: [Roles.BASE_USER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    add: {
        exact: false,
        path: paths.ADD,
        component: RentalAddPage,
        roles: [Roles.TRADER, Roles.WEBMASTER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    edit: {
        exact: false,
        path: paths.EDIT,
        component: RentalEditPage,
        roles: [Roles.TRADER, Roles.WEBMASTER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    show: {
        exact: true,
        path: paths.SHOW,
        component: RentalShowPage,
        roles: [Roles.BASE_USER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    sync: {
        exact: true,
        path: paths.SYNC,
        component: RentalSyncPage,
        roles: [Roles.TRADER],
        features: [Features.RENTAL_AGENCY],
    },
    ownerShow: {
        exact: true,
        path: paths.OWNER_SHOW,
        component: RentalOwnerShowPage,
        roles: [Roles.OWNER],
        features: [Features.TEAM_OWNER],
    },
    providerShow: {
        exact: true,
        path: paths.PROVIDER_SHOW,
        component: RentalProviderShowPage,
        roles: [Roles.PROVIDER],
        features: [Features.TEAM_PROVIDER],
    },
    planning: {
        exact: true,
        path: paths.PLANNING,
        component: RentalPlanningPage,
        roles: [Roles.BASE_USER, Roles.MANAGER],
        features: [Features.RENTAL_MANAGER, Features.RENTAL_AGENCY],
    },
    sharePlanning: {
        exact: true,
        path: paths.SHARE_PLANNING,
        component: RentalSharePlanningPage,
        roles: [Roles.MANAGER],
        features: [Features.RENTAL_MANAGER],
    },
    shareSinglePlanning: {
        exact: true,
        path: paths.SHARE_SINGLE_PLANNING,
        component: RentalShareSinglePlanningPage,
        roles: [Roles.MANAGER],
        features: [Features.RENTAL_MANAGER],
    },
};
