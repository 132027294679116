import lazyWithRetry from 'services/lazyWithRetry';
import { Features, Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const basePath = '/owner';

const ListPage = lazyWithRetry(() => import('./List'));
const AddPage = lazyWithRetry(() => import('./Add'));
const EditPage = lazyWithRetry(() => import('./Edit'));
const ShowPage = lazyWithRetry(() => import('./Show'));

export const paths = {
    LIST: basePath,
    ADD: `${basePath}/add`,
    SHOW: `${basePath}/:id/show`,
    EDIT: `${basePath}/:id/edit`,
};

export const routes: RouteCollection = {
    list: {
        exact: true,
        path: paths.LIST,
        component: ListPage,
        roles: [Roles.BASE_USER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    add: {
        exact: true,
        path: paths.ADD,
        component: AddPage,
        roles: [Roles.TRADER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    show: {
        exact: true,
        path: paths.SHOW,
        component: ShowPage,
        roles: [Roles.BASE_USER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: EditPage,
        roles: [Roles.TRADER],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
};
