import { ManagerBookingApiItem } from 'features/ManagerBooking/types';
import { paths, routes } from './pages/routes';
import lazyWithRetry from 'services/lazyWithRetry';
import { actions, selectors } from './store';
import { Resource } from 'typings/shared';
import api from './api';

export const translationKey = 'ManagerBooking';

const resource: Resource<
    ManagerBookingApiItem,
    typeof paths,
    typeof api,
    typeof actions,
    typeof selectors,
    typeof routes
> = {
    name: 'managerBooking',
    translationKey,
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('./pages')),
};

export default resource;
