import { paths, routes } from './pages/routes';
import api from 'features/Bed/api';
import lazyWithRetry from 'services/lazyWithRetry';
import { selectors, actions } from 'features/Bed/store';
import { BedApiItem } from 'features/Bed/types';
import { Resource } from 'typings/shared';

const resource: Resource<BedApiItem, typeof paths, typeof api, typeof actions, typeof selectors, typeof routes> = {
    name: 'bed',
    translationKey: 'bed',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('./pages')),
};

export default resource;
