import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MUISortOptions } from 'mui-datatables';
import { resourceInitialState } from 'store/ResourceSlice';
import { EnquiryApiItem } from 'typings/api';
import { EnquiryStateValues as EnumEnquiryState } from 'typings/enums';
import { EnquiryListType, OrNull } from 'typings/shared';
import { EnquiryState } from 'typings/state';

export const stateByType = {
    active: [
        EnumEnquiryState.STATE_CREATED,
        EnumEnquiryState.STATE_ASSIGNED,
        EnumEnquiryState.STATE_WAITING_FOR_CUSTOMER_INFORMATION,
        EnumEnquiryState.STATE_SELECTION_SENT_TO_CUSTOMER,
        EnumEnquiryState.STATE_OPTIONS_SENT,
    ],
    archived: [EnumEnquiryState.STATE_CANCELED, EnumEnquiryState.STATE_VALIDATED],
    all: [
        EnumEnquiryState.STATE_CREATED,
        EnumEnquiryState.STATE_ASSIGNED,
        EnumEnquiryState.STATE_WAITING_FOR_CUSTOMER_INFORMATION,
        EnumEnquiryState.STATE_SELECTION_SENT_TO_CUSTOMER,
        EnumEnquiryState.STATE_OPTIONS_SENT,
        EnumEnquiryState.STATE_CANCELED,
        EnumEnquiryState.STATE_VALIDATED,
    ],
};

const { list: listState } = resourceInitialState();

const initialState: EnquiryState = {
    listActive: { ...listState, filters: [[], [], [], [], [], [], stateByType.active] },
    listArchived: { ...listState, filters: [[], [], [], [], [], [], stateByType.archived] },
    listAll: { ...listState, filters: [[], [], [], [], [], [], stateByType.all] },
    selection: {
        id: undefined,
        rentals: [],
    },
    currentList: 'Active',
};

const slice = createSlice({
    name: 'enquiry',
    initialState,
    reducers: {
        resetSelection: (state): void => {
            state.selection.id = undefined;
            state.selection.rentals = [];
        },
        startSelection: (state, action: PayloadAction<EnquiryApiItem>): void => {
            state.selection.id = action.payload['@id'];
            state.selection.rentals = action.payload.selections || [];
        },
        addToSelection: (state, action: PayloadAction<string>): void => {
            if (state.selection.id && !state.selection.rentals.includes(action.payload)) {
                state.selection.rentals.push(action.payload);
            }
        },
        removeFromSelection: (state, action: PayloadAction<string>): void => {
            state.selection.rentals = [...state.selection.rentals.filter((id: string) => id !== action.payload)];
        },
        setCurrentPage: (state: EnquiryState, action: PayloadAction<number>): void => {
            state[`list${state.currentList}` as const].currentPage = action.payload;
        },
        setItemsPerPage: (state: EnquiryState, action: PayloadAction<number>): void => {
            if (state[`list${state.currentList}` as const].itemsPerPage !== action.payload) {
                state[`list${state.currentList}` as const].currentPage = 1;
            }
            state[`list${state.currentList}` as const].itemsPerPage = action.payload;
        },
        setSearch: (state: EnquiryState, action: PayloadAction<OrNull<string>>): void => {
            if (state[`list${state.currentList}` as const].search !== action.payload) {
                state[`list${state.currentList}` as const].currentPage = 1;
            }
            state[`list${state.currentList}` as const].search = action.payload;
        },
        setFilters: (state: EnquiryState, action: PayloadAction<Array<any>>): void => {
            if (state[`list${state.currentList}` as const].filters !== action.payload) {
                state[`list${state.currentList}` as const].currentPage = 1;
            }
            state[`list${state.currentList}` as const].filters = action.payload;
        },
        setSort: (state: EnquiryState, action: PayloadAction<MUISortOptions>): void => {
            state[`list${state.currentList}` as const].sort = action.payload;
        },
        setColumnVisibility: (state: EnquiryState, action: PayloadAction<{ name: string; visible: boolean }>): void => {
            state[`list${state.currentList}` as const].columns[action.payload.name] = action.payload.visible;
        },
        setCurrentList: (state: EnquiryState, action: PayloadAction<EnquiryListType>): void => {
            state.currentList = action.payload;
        },
        setColumnOrder: (state: EnquiryState, action: PayloadAction<Array<number>>): void => {
            state[`list${state.currentList}` as const].columnOrder = action.payload;
        },
    },
});

export const enquiry = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
