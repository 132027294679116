import AuthenticatedLayout from 'components/Security/AuthenticatedLayout';

import PublicLayout from 'components/Security/PublicLayout';
import lazyWithRetry from 'services/lazyWithRetry';
import { Features, Roles } from 'typings/enums';
import { RouteCollection, RouteWithLayout } from 'typings/shared';

const Login = lazyWithRetry(() => import('./Login'));
const ConnectAs = lazyWithRetry(() => import('./ConnectAs'));
const UserListPage = lazyWithRetry(() => import('./List'));
const UserAddPage = lazyWithRetry(() => import('./Add'));
const UserEditPage = lazyWithRetry(() => import('./Edit'));

export const paths = {
    USER_LOGIN: '/login',
    USER_CONNECT_AS: '/connect-as',
    LIST: '/user',
    ADD: '/user/add',
    EDIT: '/user/:id/edit',
};

export const connectRoutes: Array<RouteWithLayout> = [
    {
        exact: true,
        path: paths.USER_LOGIN,
        component: Login,
        layout: PublicLayout,
    },
    {
        exact: true,
        path: paths.USER_CONNECT_AS,
        component: ConnectAs,
        layout: AuthenticatedLayout,
        roles: [Roles.ALLOWED_TO_SWITCH],
    },
];

export const routes: RouteCollection = {
    list: {
        exact: true,
        path: paths.LIST,
        component: UserListPage,
        roles: [Roles.TEAM_MANAGER, Roles.SUPER_ADMIN],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    add: {
        exact: true,
        path: paths.ADD,
        component: UserAddPage,
        roles: [Roles.TEAM_MANAGER, Roles.SUPER_ADMIN],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: UserEditPage,
        roles: [Roles.TEAM_MANAGER, Roles.SUPER_ADMIN],
        features: [Features.RENTAL_AGENCY, Features.RENTAL_MANAGER],
    },
};
