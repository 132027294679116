import { RootState } from 'store';
import createResourceListSelectors from 'store/ResourceListSelectors';
import { ResourceListSelectors, StateResource } from 'typings/state';

const getResourceState = (state: RootState): StateResource => state.provider;

const providerSelector: ResourceListSelectors = createResourceListSelectors(getResourceState);

const selectors = { ...providerSelector };
export default selectors;
