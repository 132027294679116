import { RootState } from 'store';
import createResourceListSelectors from 'store/ResourceListSelectors';
import { ResourceListSelectors } from 'typings/state';
import { CreditNoteState } from 'features/CreditNote/types';

const selectors: ResourceListSelectors = createResourceListSelectors(
    (state: RootState): CreditNoteState => state.furnishing,
);

export default selectors;
