import { paths, routes } from 'pages/paymentMode/routes';
import api from 'resources/paymentMode/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/paymentMode/selectors';
import { actions } from 'store/paymentMode/slice';
import { PaymentModeApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<
    PaymentModeApiItem,
    typeof paths,
    typeof api,
    typeof actions,
    typeof selectors,
    typeof routes
> = {
    name: 'paymentMode',
    translationKey: 'paymentMode',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/paymentMode')),
};

export default resource;
