import lazyWithRetry from 'services/lazyWithRetry';
import { Features, Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const ListPage = lazyWithRetry(() => import('./List'));
const AddPage = lazyWithRetry(() => import('./Add'));
const EditPage = lazyWithRetry(() => import('./Edit'));
const MergePage = lazyWithRetry(() => import('./Merge'));

export const paths = {
    LIST: '/origin',
    ADD: '/origin/add',
    EDIT: '/origin/:id/edit',
    MERGE: '/origin/:id/merge',
};

export const routes: RouteCollection = {
    list: {
        exact: true,
        path: paths.LIST,
        component: ListPage,
        roles: [Roles.BASE_USER],
        features: [Features.RENTAL_AGENCY],
    },
    add: {
        exact: true,
        path: paths.ADD,
        component: AddPage,
        roles: [Roles.TRADER],
        features: [Features.RENTAL_AGENCY],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: EditPage,
        roles: [Roles.TRADER],
        features: [Features.RENTAL_AGENCY],
    },
    merge: {
        exact: true,
        path: paths.MERGE,
        component: MergePage,
        roles: [Roles.TRADER],
        features: [Features.RENTAL_AGENCY],
    },
};
