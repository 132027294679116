import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import createResourceListSelectors from 'store/ResourceListSelectors';
import { EnquiryListType } from 'typings/shared';
import { EnquirySelection, EnquiryState, ResourceListSelectors, StateResource } from 'typings/state';

const getResourceState = (state: RootState): EnquiryState => state.enquiry;

const selectSelection = createSelector(getResourceState, (state: EnquiryState): EnquirySelection => state.selection);
const selectCurrentList = createSelector(getResourceState, (state: EnquiryState): EnquiryListType => state.currentList);

const enquirySelectors: ResourceListSelectors = createResourceListSelectors(
    (state: RootState): StateResource => ({
        list: state.enquiry[`list${state.enquiry.currentList}` as const],
    }),
);

const extraSelectors = {
    selectSelection,
    selectCurrentList,
};

const selectors = { ...enquirySelectors, ...extraSelectors };

export default selectors;
