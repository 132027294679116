import lazyWithRetry from 'services/lazyWithRetry';
import { Features, Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const ListPage = lazyWithRetry(() => import('./List'));
const CreateFromEnquiryPage = lazyWithRetry(() => import('./AddFromEnquiry'));
const CreateFromPlanningPage = lazyWithRetry(() => import('./AddFromPlanning'));
const AddPage = lazyWithRetry(() => import('./Add'));
const EditPage = lazyWithRetry(() => import('./Edit'));
const ShowPage = lazyWithRetry(() => import('./Show'));

export const paths = {
    LIST: '/booking/list',
    CREATE_FROM_ENQUIRY: '/booking/create-from-enquiry/:enquiryId/:rentalId',
    ADD_FROM_PLANNING: `/booking/create-from-planning/:checkIn/:checkOut/:rentalId`,
    ADD: '/booking/add',
    ADD_STEP_2: '/booking/add/step2',
    ADD_STEP_3: '/booking/add/step3',
    EDIT: '/booking/:id/edit',
    SHOW: '/booking/:id/show',
};

export const routes: RouteCollection = {
    list: {
        exact: false,
        path: paths.LIST,
        component: ListPage,
        roles: [Roles.BASE_USER],
        features: [Features.RENTAL_AGENCY],
    },
    createFromEnquiry: {
        exact: false,
        path: paths.CREATE_FROM_ENQUIRY,
        component: CreateFromEnquiryPage,
        roles: [Roles.TRADER],
        features: [Features.RENTAL_AGENCY],
    },
    addFromPlanning: {
        exact: false,
        path: paths.ADD_FROM_PLANNING,
        component: CreateFromPlanningPage,
        roles: [Roles.TRADER],
        features: [Features.RENTAL_AGENCY],
    },
    add: {
        exact: false,
        path: paths.ADD,
        component: AddPage,
        roles: [Roles.TRADER],
        features: [Features.RENTAL_AGENCY],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: EditPage,
        roles: [Roles.TRADER],
        features: [Features.RENTAL_AGENCY],
    },
    show: {
        exact: true,
        path: paths.SHOW,
        component: ShowPage,
        roles: [Roles.TRADER],
        features: [Features.RENTAL_AGENCY],
    },
};
