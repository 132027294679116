import { ProviderApiItem } from 'features/Provider/types';
import { paths, routes } from './pages/routes';
import lazyWithRetry from 'services/lazyWithRetry';
import { actions, selectors } from './store';
import { Resource } from 'typings/shared';
import api from './api';

export const translationKey = 'Provider';

const resource: Resource<ProviderApiItem, typeof paths, typeof api, typeof actions, typeof selectors, typeof routes> = {
    name: 'provider',
    translationKey,
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('./pages')),
};

export default resource;
