import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { FeatureState } from 'typings/state';

export const initialState: FeatureState = resourceInitialState();

const slice = createResourceSlice({
    name: 'feature',
    initialState,
});

export const feature = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
