import { paths, routes } from 'pages/roomType/routes';
import api from 'resources/roomType/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/roomType/selectors';
import { actions } from 'store/roomType/slice';
import { RoomTypeApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<RoomTypeApiItem, typeof paths, typeof api, typeof actions, typeof selectors, typeof routes> = {
    name: 'roomType',
    translationKey: 'roomType',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/roomType')),
};

export default resource;
