import { AxiosPromise } from 'axios';
import { RentalApiItem } from 'features/Rental/types';
import { createResourceApi, DEFAULT_FETCHED_ITEMS_PER_AGE } from 'resources/ResourceApi';
import { normalizeUri, requestApi } from 'services/axios';
import { CollectionJsonLD, ManagerApiItem } from 'typings/api';

const rentals = (
    manager: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<RentalApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('managers', manager)}/rentals`,
        params: queryParameters,
    });
};

export default { ...createResourceApi<ManagerApiItem>('managers'), rentals };
