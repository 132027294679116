import { AxiosPromise, AxiosRequestConfig } from 'axios';
import {
    ManagerBookingAcceptedStateUpdate,
    ManagerBookingApiItem,
    ManagerBookingStateUpdate,
} from 'features/ManagerBooking/types';
import { createResourceApi, DEFAULT_FETCHED_ITEMS_PER_AGE } from 'resources/ResourceApi';
import { normalizeUri, requestApi } from 'services/axios';
import { AdditionalLineApiItem, ApiPushItem, BookingLineApiItem, CollectionJsonLD } from 'typings/api';
import { OwnerPaymentFormValues } from 'typings/forms';

export const apiKey = 'manager_bookings';

const bookingLines = (
    booking: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<BookingLineApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri(apiKey, booking)}/booking_lines`,
        params: queryParameters,
    });
};

const additionalLines = (
    booking: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<AdditionalLineApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri(apiKey, booking)}/additional_lines`,
        params: queryParameters,
    });
};

const bookingLinePrice = (
    booking: string,
    bookingLine: Omit<ApiPushItem<BookingLineApiItem>, 'nightCount' | 'baseWeeklyPrice' | 'billedAmount'>,
): AxiosPromise<Array<BookingLineApiItem>> =>
    requestApi({
        url: `/manager_booking_lines/prices`,
        method: 'POST',
        data: {
            ...bookingLine,
            booking: normalizeUri(apiKey, booking),
        },
    });

const ownerPayment = (
    bookingId: string,
    data: OwnerPaymentFormValues,
    options?: AxiosRequestConfig,
): AxiosPromise<ManagerBookingApiItem> =>
    requestApi({
        url: `${normalizeUri(apiKey, bookingId)}/owner-payment`,
        method: 'PATCH',
        data,
        ...options,
        headers: {
            ...options?.headers,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/merge-patch+json',
        },
    });

const updateBookingState = (data: ManagerBookingStateUpdate): AxiosPromise<ManagerBookingApiItem> =>
    requestApi({
        url: `${normalizeUri(apiKey, data.booking)}/state`,
        method: 'PATCH',
        headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/merge-patch+json',
        },
        data,
    });

const updateAcceptedState = (data: ManagerBookingAcceptedStateUpdate): AxiosPromise<ManagerBookingApiItem> =>
    requestApi({
        url: `${normalizeUri(apiKey, data.booking)}/accepted_state`,
        method: 'PATCH',
        headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/merge-patch+json',
        },
        data,
    });

export default {
    ...createResourceApi<ManagerBookingApiItem>(apiKey),
    bookingLines,
    additionalLines,
    bookingLinePrice,
    ownerPayment,
    updateBookingState,
    updateAcceptedState,
};
