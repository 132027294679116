import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { CreditNoteState } from 'features/CreditNote/types';

export const initialState: CreditNoteState = resourceInitialState();

const slice = createResourceSlice({
    name: 'creditNote',
    initialState,
});

export const creditNote = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
