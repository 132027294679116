import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { normalizeUri, requestApi } from 'services/axios';
import { createResourceApi, DEFAULT_FETCHED_ITEMS_PER_AGE } from 'resources/ResourceApi';
import { AuthApiToken, CollectionJsonLD, TeamApiItem, UserApiItem } from 'typings/api';
import { LoginFormValues } from 'typings/forms';

const login = (payload: LoginFormValues): AxiosPromise<AuthApiToken> =>
    requestApi({
        secure: false,
        method: 'POST',
        url: 'authentication_token',
        data: payload,
        showBackdrop: true,
    });

const getProfile = (admin: boolean): AxiosPromise<UserApiItem> =>
    requestApi({
        url: admin ? 'admin_users/me' : '/users/me',
    });

const getTeams = (): AxiosPromise<CollectionJsonLD<TeamApiItem>> =>
    requestApi({
        url: '/teams',
    });

const getTeamUsers = (
    team: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
    filters?: Record<string, any>,
    config?: AxiosRequestConfig,
): AxiosPromise<CollectionJsonLD<UserApiItem>> =>
    requestApi({
        url: `${normalizeUri('teams', team)}/users`,
        params: {
            ...filters,
            page,
            perPage: itemsPerPage,
        },
        ...config,
    });

export default {
    ...createResourceApi<UserApiItem>('users'),
    login,
    getProfile,
    getTeams,
    getTeamUsers,
};
