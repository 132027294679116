import { LinearProgress } from '@material-ui/core';
import { useEntityFetch } from 'hooks/useCrudQueries';
import resource from 'features/Rental';
import React, { ReactElement } from 'react';

type Props = {
    id?: string;
};

const RentalLabel = ({ id }: Props): ReactElement | null => {
    const { isLoading, data } = useEntityFetch(resource, id || null, { headers: { 'with-archived': '1' } });
    if (isLoading) {
        return <LinearProgress />;
    }

    if (null === data || undefined === id) {
        return null;
    }

    return <>{data?.title}</>;
};

export default RentalLabel;
