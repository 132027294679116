import { paths, routes } from './pages/routes';
import api from './api';
import lazyWithRetry from 'services/lazyWithRetry';
import { actions, selectors } from './store';
import { CompetitorApiItem } from 'features/Competitor/types';
import { Resource } from 'typings/shared';

export const translationKey = 'Competitor';

const resource: Resource<CompetitorApiItem, typeof paths, typeof api, typeof actions, typeof selectors, typeof routes> =
    {
        name: 'competitor',
        translationKey: translationKey,
        api,
        selectors,
        paths,
        routes,
        actions,
        component: lazyWithRetry(() => import('./pages')),
    };

export default resource;
